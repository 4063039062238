import * as React from "react";
import Navigation from "../components/Navigation";
import UxLawsAndPrinciplesSeo from "../components/SEO/UxLawsAndPrinciplesSeo";
import "../styles/style.css";

const UxLawsAndPrinciples = () => {
  const UxLaws = [
    {
      title: "Hick's Law",
      content:
        "The more choices presented to a user, the more time the user needs to reach a decision.",
    },
    {
      title: "Fitt's Law",
      content:
        "The time it takes a user to move the pointer to a target. Smaller the target, longer it takes the user to reach it.",
    },
    {
      title: "Pareto Principle",
      content:
        "80% of results are produced by 20% of causes. Focus on the areas that will produce max effect on users.",
    },
    {
      title: "Occam's Razor",
      content:
        "The simplest solution is almost always the best. Simplicity is better than complexity.",
    },
    {
      title: "Jakob's Law",
      content:
        "Users spend most of their time on other sites. They want yours to work the same way due to familiarity.",
    },
    {
      title: "Peak-End Rule",
      content:
        "This a heuristic rule in which people judge an experience largely based on how they felt at its peak.",
    },
    {
      title: "Doherty Threshold",
      content:
        "The idea that a user's experience is more positive after the system feedback time drops below 400ms.",
    },
    {
      title: "Law of Proximity",
      content:
        "We perceive that elements that are close to each other are perceived to be related.",
    },
    {
      title: "Miller's Law",
      content:
        "Most adults can store between 5 and 9 items in their short-term memory.",
    },
    {
      title: "Zeigarnik Effect",
      content:
        "A psycological tendency to remember interrupted tasks or events more easily than tasks that have been completed.",
    },
    {
      title: "The Isolation Effect",
      content:
        "The idea that similar objects are present, the one that differs from the rest is most likely to be remembered!",
    },
    {
      title: "Aesthetic Usability Effect",
      content:
        "The idea that the more aesthetically pleasing an interface is, the easier it'll be to use",
    },
  ];
  return (
    <main>
      <div className="content">
        <UxLawsAndPrinciplesSeo></UxLawsAndPrinciplesSeo>
        <Navigation></Navigation>
        <div className="about__section-1">
          <div>
            <div className="glass">
              <h1>UX Laws and Principles</h1>
            </div>
          </div>
          <div className="lat__bullet-circle--container">
            <div>
              <div className="bullet-circle"></div>
              <p> An overview of the UX Laws and Principles</p>
            </div>
            <div>
              <div className="bullet-circle"></div>
              <p>Implementing these laws will improve the user experience</p>
            </div>
          </div>
        </div>
        <div className="lat__advice-grid laws__advice-grid">
          {UxLaws.map((law) => (
            <div class="glass mobile-span">
              <div className="lat__panel-title">{law.title}</div>
              <div className="bullet-list bullet-list-laws">
                <div className="bullet-list__bullet-container">
                  <div className="bullet-circle"></div>
                </div>
                <div className="bullet-list__content">{law.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default UxLawsAndPrinciples;
